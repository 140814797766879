import Swiper from 'react-id-swiper';
import { Pagination } from 'swiper/dist/js/swiper.esm';
import 'react-id-swiper/src/styles/scss/swiper.scss';


function Carousel(props) {
  const params = {
    modules: [Pagination],
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
    grabCursor: true,
  };

  return (
    <Swiper {...params} {...props}>
      {props.children}
    </Swiper>
  );
}


export default Carousel;
