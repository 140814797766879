/**
 * @see {BreakpointObserver}
 * @enum {string}
 */
export const BREAKPOINTS = {
  MINI: 'mini',
  SMALL: 's',
  MEDIUM: 'm',
  LARGE: 'l',
  XLARGE: 'xl',
};

export const BREAKPOINT_SIZES = {
  MINI: 0,
  SMALL: 321,
  MEDIUM: 600,
  LARGE: 1024,
  XLARGE: 1600,
};

// Refactor.
export function biggerThanTablet(breakpoint) {
  return breakpoint === BREAKPOINTS.LARGE || breakpoint === BREAKPOINTS.XLARGE;
}


/** @type {{access_token: string, scope: string, created_at: number, token_type: string}} */
const DRIBBBLE_AUTH = {
  access_token: '75281fdef7b4437f9365b1dd8a4b24e55d02bb97cc9152d230f7d0f4a31f5d26',
  token_type: 'Bearer',
  scope: 'public',
  created_at: 1550329490,
};

/** @type {string} */
const DRIBBBLE_ENDPOINT = `https://api.dribbble.com/v2/user/shots?access_token=${DRIBBBLE_AUTH.access_token}`;

/** @type {{ACCESS_TOKEN: string, ENDPOINT: string}} */
export const DRIBBBLE = {
  ACCESS_TOKEN: DRIBBBLE_AUTH.access_token,
  ENDPOINT: `${DRIBBBLE_ENDPOINT}&page=1&per_page=100`,
};

export const DRIBBBLE_TAG_FILTER = 'experiments';
