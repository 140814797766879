import style from './style.scss';


function CaseStudyHeaderWithoutSemantics({ title, prefixes }) {
  return (
    <div class={style.Header}>
      <p>
        {prefixes.map(prefix => <span>{prefix.titlePrefix}</span>)}
      </p>
      <p class="fake-heading h2">{title}</p>
    </div>
  );
}

CaseStudyHeaderWithoutSemantics.defaultProps = {
  title: '',
  prefixes: [],
};

export default CaseStudyHeaderWithoutSemantics;
