import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/storage';


const firebaseConfig = {
  apiKey: 'AIzaSyDljWoAdaE1xhgpRqS0a1lNhgC6DtqamR0',
  authDomain: 'dianacostastaging.firebaseapp.com',
  databaseURL: 'https://dianacostastaging.firebaseio.com',
  projectId: 'dianacostastaging',
  storageBucket: 'dianacostastaging.appspot.com',
  messagingSenderId: '848902922782',
  appId: '1:848902922782:web:b464859ba5a62e2d',
};

firebase.initializeApp(firebaseConfig);
export const storage = firebase.storage();
export const database = firebase.database();

const storageRef = storage.ref();


export async function getBucket() {
  const fileReference = await storageRef.child('flamelink/media/').listAll();
  const { items } = fileReference;
  const filesToReturn = {};

  await Promise.all(items.map(async(item) => {
    filesToReturn[item.name] = await item.getDownloadURL();
  }));

  return filesToReturn;
}

export function getFile(files, fileName, fileActualName = '') {
  if (!files) {
    return;
  }

  let fileWeNeed = Object.keys(files).find(key => key.includes(fileName));

  if (!fileWeNeed) {
    let title = fileActualName.replace(/\s+/g, '-').toLowerCase();
    fileWeNeed = Object.keys(files).find(key => key.includes(`${title}.`));
  }
  return files[fileWeNeed];
}

export const FOOTER_PATH = 'flamelink/environments/production/navigation/footer/en-US/items/';
export const FOOTER = database.ref(FOOTER_PATH);

export const PROJECTS_PATH = 'flamelink/environments/production/content/projects/en-US/';
export const PROJECTS = database.ref(PROJECTS_PATH);

export function sortNumber(a, b) {
  return a - b;
}
