import 'gsap/CSSPlugin';
import Router from 'preact-router';
import {useContext, useEffect, useRef} from 'preact/hooks';
import {initialize, pageview} from 'react-ga';
import {CMSContext} from '../../common/providers/cmsProvider';
import CaseStudy from '../CaseStudy';

import Home from '../../routes/home';
import Nav from '../../routes/nav';
import style from './style.scss';


export function Shell() {
  const {projects} = useContext(CMSContext);
  const analyticsInitialised = useRef(false);

  function handleRouteChange(event) {
    window.scrollTo(0, 0);
    if (analyticsInitialised.current) {
      pageview(event.url);
    }
  }

  useEffect(() => {
    if (!analyticsInitialised.current) {
      initialize('UA-156821381-1');
      pageview(window.location.pathname + window.location.search);
      analyticsInitialised.current = true;
    }
  });

  return (
      <main id="main">
        <div>
          <Router onChange={handleRouteChange}>
            <Home default loaded={Object.keys(projects).length}/>

            <Nav path="/work" items={projects}/>

            {Object.keys(projects).length > 0 && Object.keys(projects).map(key => {
              const project = projects[key];
              return (
                  <CaseStudy key={project.url} path={project.url} content={project}/>
              );
            })}
          </Router>
        </div>
      </main>
  );
}
