export function Logo(props) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <g fill="currentColor">
        <path d="M16 0a16 16 0 1 0 0 32 16 16 0 0 0 0-32m0 1a15 15 0 1 1 0 30 15 15 0 0 1 0-30"/>
        <mask id="moon-mask" fill="#fff">
          <circle cx="16" cy="16" r="16"/>
        </mask>
        <path d="M8 0a16 16 0 1 0 0 32A16 16 0 0 0 8 0m0 1a15 15 0 1 1 0 30A15 15 0 0 1 8 1"
              mask="url(#moon-mask)"/>
      </g>
    </svg>
  );
}


export function Arrow(props) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 33">
      <path fill="currentColor" d="M33 0H0v3h28L0 31l2 2L30 5v28h3z"/>
    </svg>
  );
}
