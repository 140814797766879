import style from './style.scss';


function CaseStudyHeader({ title, prefixes }) {
  return (
    <header class={style.Header}>
      <div class="container">
        <div class="container--inner">
          <p>
            {prefixes.map(prefix => <span>{prefix.titlePrefix}</span>)}
          </p>
          <h1>{title}</h1>
        </div>
      </div>
    </header>
  );
}

CaseStudyHeader.defaultProps = {
  title: '',
  prefixes: [],
};

export default CaseStudyHeader;
