import { useContext } from 'preact/hooks';
import { CMSContext, getProjectByID } from '../../common/providers/cmsProvider';
import NavItem from '../NavItem';
import style from './style.scss';


function Footer(props) {
  const {
    items = [],
    onButtonClick = () => {},
    cta = 0,
    custom = null,
    email = null,
    emailPrefix = null,
  } = props;

  const { projects } = useContext(CMSContext);
  const ctaToLinkTo = getProjectByID(projects, cta);

  return (
    <footer {...props} className={style.Footer}>
      <div className="container">
        <nav className="container--inner">

          {ctaToLinkTo && (
            <div>
              <NavItem url={ctaToLinkTo.url} content={ctaToLinkTo.menuEntry} onClick={onButtonClick} showImage={false}/>
            </div>
          )}

          {custom && (
            <div className={style.Email}>
              <NavItem content={custom} showArrow={false}/>
            </div>
          )}

          <ul>
            {items.map(item => {
              const isActive = item.url === window.location.pathname;

              return (
                <li key={`footer-${item.url}`}>
                  <button
                    inert={isActive}
                    className={isActive ? style.Active : ''}
                    data-route={item.url}
                    onClick={onButtonClick}
                  >{item.title}</button>
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
    </footer>
  );
}

export default Footer;
