import {Expo, Power2} from 'gsap';
import {TimelineLite} from 'gsap/TimelineLite';
import {TweenLite} from 'gsap/TweenLite';
import {Component} from 'preact';
import {route} from 'preact-router';
import {Fragment, useState} from 'preact/compat';
import {useContext} from 'preact/hooks';
import {getFile} from '../../common/cms';
import {CMSContext} from '../../common/providers/cmsProvider';
import Carousel from '../../components/Carousel';
import CaseStudyHeader from '../../components/CaseStudyHeader';
import Footer from '../../components/Footer';
import VimeoPlayer from '../vimeoPlayer';

import style from './style.scss';


class CaseStudy extends Component {
  state = {
    authenticated: !!!this.props.content.password,
  };

  componentDidMount() {
    this.timeline = new TimelineLite();

    this.fadeDistance = 200;

    if (this.article) {
      this.onActualMount();
    }
  }

  componentDidUpdate(previousProps, previousState, snapshot) {
    if (previousState.authenticated !== this.state.authenticated) {
      if (this.state.authenticated) {
        this.onActualMount();
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.onScroll);
    document.removeEventListener('resize', this.onResize);
    document.documentElement.classList.remove('about');
  }

  onScroll = () => {
    const hero = this.article.children[0];

    if (!hero) {
      return;
    }

    const scroll = document.scrollingElement.scrollTop;

    TweenLite.set(hero, {
      autoAlpha: Math.max((this.fadeDistance - scroll) / this.fadeDistance, 0),
    });
  };

  onActualMount = () => {
    const children = [...this.article.children];
    const childrenToAnimate = children.slice(0, 4);

    this.timeline.staggerFromTo(childrenToAnimate, 1, {
      y: window.innerHeight,
      autoAlpha: 0,
    }, {
      y: 0,
      autoAlpha: 1,
      ease: Expo.easeOut,
    }, 0.2);

    document.querySelectorAll('video').forEach(video => {
      video.setAttribute('muted', '');
    });

    document.addEventListener('scroll', this.onScroll);
    window.addEventListener('resize', this.onResize);
    this.onScroll();
    this.onResize();

    if (this.props.content.compactMode) {
      document.documentElement.classList.add('about');
    }
  }

  onResize = () => {
    const hero = this.article.children[0];
    if (!hero) {
      return;
    }

    const secondElement = this.article.children[1];

    hero.style.position = 'relative';
    hero.style.removeProperty('height');

    const {height} = hero.getBoundingClientRect();
    const gridGap = getComputedStyle(this.article).rowGap;
    const gap = parseInt(gridGap.replace('px', ''));

    if (secondElement) {
      secondElement.style.marginTop = `${height + gap}px`;
    }

    hero.style.removeProperty('position');

    this.fadeDistance = height + gap;
  };

  _onFooterButtonClick = (event) => {
    const element = event.target;
    const href = element.dataset.route;

    TweenLite.to(this.transitionOverlay, 1, {
      scaleY: 1,
      ease: Power2.easeInOut,
      onComplete: () => {
        route(href);
      },
    });
  };

  _onPasswordSubmit = (event) => {
    event.preventDefault();
    if (event.target.children[0].children[1].value === this.props.content.password) {
      this.setState({authenticated: true});
    }
    event.target.reset();
  };

  render({content = {}}) {
    const {footerItems, files} = useContext(CMSContext);
    const {hero, blocks, dribbblePosts, compactMode, aboutImage, customFooter = {}} = content;
    const customFooterData = customFooter.show ? customFooter : null;

    return (
        <Fragment>

          {this.state.authenticated ? (
              <article className={`page ${style.CaseStudy} ${compactMode && style.About}`} ref={n => this.article = n}>
                {hero && <CaseStudyHeader title={hero.title} prefixes={hero.titlePrefixes}/>}

                {blocks && blocks.map(block => (
                    <Fragment>
                      {block.video && (
                          <div className="container">
                            <div className={style.Video}>
                              <video muted autoPlay loop playsinline>
                                <source src={getFile(files, block.video)} type="video/mp4"/>
                              </video>
                            </div>
                          </div>
                      )}

                      {block.image && block.image.map(image => (
                          <div className="container">
                            <picture>
                              <img src={getFile(files, image)} alt={content.title}/>
                            </picture>
                          </div>
                      ))}

                      {block.vimeo && (
                          <div className="container">
                            <VimeoPlayer id={block.vimeo}/>
                          </div>
                      )}

                      {block.paragraph && (
                          <div className="container">
                            <p className="container--inner"><span>{block.paragraph}</span></p>
                          </div>
                      )}

                      {block.dangerousParagraph && (
                          <div className="container">
                            <div className="container--inner"
                                 dangerouslySetInnerHTML={{__html: block.dangerousParagraph}}/>
                          </div>
                      )}

                      {block.carousel && (
                          <div className={`container ${style.Slider}`}>
                            <Carousel spaceBetween={20} loop={false} loopedSlides={3} slideToClickedSlide>
                              {block.carousel.map(slide => (
                                  <div key={slide.uniqueKey}>
                                    <div className={style.Slide}>
                                      <img src={getFile(files, slide.image)} alt={slide.title}/>
                                    </div>
                                  </div>
                              ))}
                            </Carousel>
                          </div>
                      )}
                    </Fragment>
                ))}

                {dribbblePosts && (
                    <div className="container">
                      <div className={style.DribbbleGrid}>
                        {dribbblePosts.map(post => {
                          return (
                              <a href={post.html_url} target="_blank" rel="noopener noreferrer nofollow">
                                <picture>
                                  <img src={post.images.hidpi} alt={content.title}/>
                                </picture>
                              </a>
                          );
                        })}
                      </div>
                    </div>
                )}

                {aboutImage && aboutImage.map(image => {
                  console.log(files);
                  const imagePath = getFile(files, image);

                  console.log(imagePath);
                  return (
                      <div className="container">
                        <div className="container--inner">
                          <picture className={style.AboutImage}>
                            <img src="https://firebasestorage.googleapis.com/v0/b/dianacostastaging.appspot.com/o/flamelink%2Fmedia%2Fabout-me.jpg?alt=media&token=cb66cb87-88ba-4c57-a37f-afe240834554" alt={content.title}/>
                          </picture>
                        </div>
                      </div>
                  );
                })}
              </article>
          ) : (
              <div className={style.password}>
                <form onSubmit={this._onPasswordSubmit}>
                  <label>
                    <span>Password?</span>
                    <input type="text"/>
                  </label>
                  <div>
                    <span>&nbsp;</span>
                    <button type="submit">Submit</button>
                  </div>
                </form>
              </div>
          )}

          <Footer
              cta={content.footerLink}
              custom={customFooterData}
              items={footerItems}
              onButtonClick={this._onFooterButtonClick}
          />

          <div className={style.TransitionOverlay} ref={n => this.transitionOverlay = n}/>
        </Fragment>
    );
  }
}


export default CaseStudy;
