import { useContext } from 'preact/hooks';
import { getFile } from '../../common/cms';
import { CMSContext } from '../../common/providers/cmsProvider';
import CaseStudyHeaderWithoutSemantics from '../CaseStudyHeader/caseStudyHeaderWithoutSemantics';
import style from './style.scss';
import { Arrow } from '../svg';


function NavItem({ url, content, onClick, showArrow, showImage }) {
  const { files } = useContext(CMSContext);
  const { image, title, titlePrefixes } = content;

  const hasImage = image && showImage;

  return (
    <button data-route={url} onClick={onClick} class={style.Button}>
      {hasImage && <img src={getFile(files, image, title)} alt={title}/>}
      <CaseStudyHeaderWithoutSemantics title={title} prefixes={titlePrefixes}/>
      {showArrow && <Arrow class={style.Arrow}/>}
    </button>
  );
}

NavItem.defaultProps = {
  url: '',
  content: {},
  onClick: () => {
  },
  showArrow: true,
  showImage: true,
};

export default NavItem;
