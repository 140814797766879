import { Component } from 'preact';
import Player from '@vimeo/player';

import style from './style.scss';

const VIMEO_EVENTS = {
  ENDED: 'ended',
  PLAY: 'play',
};

class VimeoPlayer extends Component {
  static defaultProps = {
    autoplay: true,
    color: '#c4840e',
    loop: true,
    muted: true,
    interactive: true,
  };

  constructor(props) {
    super(props);

    /** @type {?Player} */
    this.player = null;

    /** @type {?HTMLDivElement} */
    this.playerElement = null;
  }

  componentDidMount() {
    this.player = new Player(this.playerElement);
    this.player.setColor(this.props.color);
    this.player.setLoop(this.props.loop);

    if (this.props.autoplay) {
      this.player.play();
    }

    if (this.props.muted) {
      this.player.setVolume(0);
    }

    if (!this.props.interactive) {
      this.playerElement.style.pointerEvents = 'none';
    }

    this.addEventListeners();
  }

  componentWillUnmount() {
    this.removeEventListeners();
    this.player.destroy();
  }

  onPlay = () => {};

  onEnded = () => {};

  addEventListeners() {
    const { ENDED, PLAY } = VIMEO_EVENTS;

    this.player.on(ENDED, this.onEnded);
    this.player.on(PLAY, this.onPlay);
  }

  removeEventListeners() {
    const { ENDED, PLAY } = VIMEO_EVENTS;

    this.player.off(ENDED, this.onEnded);
    this.player.off(PLAY, this.onPlay);
  }

  render(props) {
    return (
      <div
        data-vimeo-id={props.id}
        data-vimeo-width="800"
        class={style.VimeoPlayer}
        ref={element => this.playerElement = element}
      />
    );
  }
}

export default VimeoPlayer;
