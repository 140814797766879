import { Component, createContext } from 'preact';
import { FOOTER, getBucket, PROJECTS } from '../cms';
import { DRIBBBLE } from '../constants';
import { getDribbblePosts } from '../dribbbleStorage';


export const CMSContext = createContext('cms');

export function getProjectByID(projects, id) {
  return Object.values(projects).find((value) => value.id === id);
}


export class CMSProvider extends Component {
  state = {
    footerItems: [],
    files: [],
    projects: {},
  };

  componentDidMount() {
    FOOTER.on('value', (snapshot) => {
      let footerItems = [];

      const items = snapshot.val();
      const itemKeys = Object.keys(items);
      itemKeys.forEach((key, index) => footerItems[index] = items[key]);

      this.setState({ footerItems });
    });

    PROJECTS.on('value', async(snapshot) => {
      const projectsSnapshot = snapshot.val();

      console.log(snapshot, projectsSnapshot);
      let projects = {};

      for (let i = 0; i < Object.keys(projectsSnapshot).length; i += 1) {
        const key = Object.keys(projectsSnapshot)[i];
        const project = projectsSnapshot[key];
        const { dribbble, dribbbleTagFilter, url } = project;

        if (dribbble) {
          project.dribbblePosts = await getDribbblePosts(DRIBBBLE.ENDPOINT, dribbbleTagFilter);
        }

        // Use the url as a key for the main projects directory.
        projects[url] = project;
      }

      this.setState({ projects });
    });

    this.getFiles();
  }

  async getFiles() {
    const files = await getBucket();
    this.setState({ files });
  }

  render(props, state) {
    return (
      <CMSContext.Provider value={state}>
        {props.children}
      </CMSContext.Provider>
    );
  }
}
