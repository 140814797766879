import { Component } from 'preact';
import { CMSProvider } from '../common/providers/cmsProvider';
import { Shell } from './Shell';
import 'focus-visible';
import 'wicg-inert';


class App extends Component {
  render() {
    return (
      <CMSProvider>
        <Shell/>
      </CMSProvider>
    );
  }
}


export default App;
