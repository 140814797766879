import { DRIBBBLE_TAG_FILTER } from './constants';


let downloadedFiles = [];

/**
 * @param {string} endpoint - Dribbble API endpoint
 * @return {Promise<Array>}
 */
export async function getDribbblePosts(endpoint, filter) {
  if (downloadedFiles && downloadedFiles.length) {
    return downloadedFiles;
  }

  const response = await fetch(endpoint, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    headers: { 'Content-Type': 'application/json' },
    referrer: 'no-referrer',
  });

  const json = await response.json();

  const selectedPosts = json.filter(post => post.tags.includes(filter));

  // Store the files in a variable so we don't abuse dribbble's API by accident.
  downloadedFiles = selectedPosts;

  return selectedPosts;
}
